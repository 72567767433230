import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

const ServiceListItem = props => {
  console.log(props)
  return (
    <article className={props.container}>
      <h2>{props.name}</h2>
      <Img fluid={props.image.asset.fluid} className={props.imageWrapper} />
      <Link to={`/services/${props.slug.current}`}>More Info....</Link>
    </article>
  )
}

export default ServiceListItem
