import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import ServiceListItem from "../components/service-list-item"
import SEO from "../components/seo"
import serviceListStyles from "../styles/services-list.module.scss"

export const query = graphql`
  query ServiceQuery {
    services: allSanityService(sort: { fields: sortOrder, order: ASC }) {
      edges {
        node {
          id
          image {
            asset {
              fluid(maxWidth: 300, maxHeight: 400) {
                ...GatsbySanityImageFluid
              }
            }
          }
          name
          price
          _rawDescription
          slug {
            current
          }
        }
      }
    }
  }
`

const ServiceList = props => {
  console.log(props)
  return (
    <section>
      <SEO
        title="Services"
        description="Find out about the different services we offer at Crazy Critters Experience"
      />
      <h1>Services</h1>

      <div className={serviceListStyles.servicesContainer}>
        <div className={serviceListStyles.infoText}>
          <h2>
            Check below for more information on the difference services/packages
            we offer, our prices start from £150.
          </h2>
          <h3>
            Give Luke a call on 07546912001, drop us an email at
            crazycrittersexperience@outlook.com or use our contact page to get
            specific info on any of the packages below.
          </h3>
        </div>
        {props.nodes &&
          props.nodes.map(node => (
            <ServiceListItem
              {...node}
              key={node.id}
              container={serviceListStyles.container}
              imageWrapper={serviceListStyles.imageWrapper}
            />
          ))}
      </div>
    </section>
  )
}

const Services = ({ data }) => {
  const serviceNodes = data && data.services.edges.map(edge => edge.node)
  return <Layout>{serviceNodes && <ServiceList nodes={serviceNodes} />}</Layout>
}

export default Services
